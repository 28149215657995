.Loader
    display: flex
    justify-content: center
    margin: 80px 0

.loader_container
    --b: 30px
    --n: 11
    width: 120px
    aspect-ratio: 1
    border-radius: 50%
    padding: 1px
    background: conic-gradient(#0000,#000) content-box
    -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg calc(360deg/var(--n) - 20deg - 1deg),#0000 calc(360deg/var(--n) - 20deg) calc(360deg/var(--n))),radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)))
    mask: repeating-conic-gradient(#0000 0deg, #000 1deg calc(360deg/var(--n) - 20deg - 1deg), #0000 calc(360deg/var(--n) - 20deg) calc(360deg/var(--n))),radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)))
    -webkit-mask-composite: destination-in
    mask-composite: intersect
    animation: load 1s infinite steps(var(--n))

@keyframes load 
    to
        transform: rotate(1turn)
