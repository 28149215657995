
*
   margin: 0
   padding: 0
   box-sizing: border-box

*::after,
*::before
   box-sizing: border-box

body
   font-family: Inter
   background: #F8F8F8
   &::-webkit-scrollbar
      width: 8px
      background: #EFEFEF
      
   &::-webkit-scrollbar-thumb
      background-color: #BABABA
      border-radius: 9em

.loader
   height: 100vh
   width: 100vw
   display: flex
   align-items: center
   justify-content: center

button
   font-size: 16px
   font-weight: 500
   height: 44px
   color: #383838
   cursor: pointer
   border-radius: 6px

button:disabled
   cursor: not-allowed
   background: #E0E0E0
   color: rgba(137, 137, 137, 0.6)
   &:hover
      background: #E0E0E0 !important
      box-shadow: none

.rdp
   margin: 0 !important
   padding: 10px
.my-selected:not([disabled])
   font-weight: bold
   border: 2px solid currentColor
.my-selected:hover:not([disabled])
   border-color: blue
   color: blue
.my-today
   font-weight: bold
   font-size: 140%
   color: red

.btn_main
   height: 60px

.btn_step
   max-width: 360px
   width: 100%
   background: linear-gradient(94.21deg, #FEC863 1.99%, #F6A442 100%)
   border: 0
   outline: 0

   &:hover
      background: #FFCC66
      box-shadow: 0px 4px 8px rgba(255, 181, 92, 0.25)

   &:active
      background: #FFB55C

.btn_step_skip
   width: 183px
   color: #898989
   background: transparent
   border: 2px solid #BABABA

   &:hover
      border: 2px solid #898989

   &:focus
      background: #EFEFEF

.btn_step_decline
   width: 183px
   color: #898989
   background: #FFF
   border: 1.8px solid #E58D25
   border-radius: 8px

   &:hover
      border: 1.8px solid #FFCC66

   &:focus
      background: #EFEFEF

.errors
   font-size: 14px
   color: #FF4343
   padding-top: 5px

.errorsForm
   border: 1px solid #FF4343 !important

   &:hover
      border: 1px solid #FF4343 !important

select
   -moz-appearance: none
   appearance: none
   -webkit-appearance: none
   background: url('../img/arrow.svg') no-repeat right #EFEFEF !important
   background-position-x: calc(100% - 10px) !important

input[type=text],input[type=password], input[type=number], select, textarea
   font-family: Inter
   width: 100%
   padding: 12px
   font-size: 16px
   background: #EFEFEF
   color: #383838
   border: 1px solid rgba(217, 217, 217, 0.6)
   border-radius: 8px
   outline: 0
   &:hover
      background: #FFF
      border: 1px solid #CECECE !important

   &:focus
      background: #FFF
      border: 1px solid #FFCC66 !important
      outline: 1px solid #FFCC66 !important
      color: #383838

.css-t3ipsp-control:hover
   border: 1px solid #FFCC66 !important
.css-t3ipsp-control
   box-shadow: 0 0 0 1px #FFCC66 !important
   border-color: #FFCC66 !important
.css-12a83d4-MultiValueRemove
   cursor: pointer
.css-b62m3t-container
   max-width: 300px

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button
   -webkit-appearance: none
   margin: 0
   display: none
input[type="number"]
   appearance: textfield
   -moz-appearance: textfield
   -webkit-appearance: textfield

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder
   color: #b0aeae
   opacity: 1
   transition: opacity 0.3s ease
input::-moz-placeholder, textarea::-moz-placeholder
   opacity: 1
   transition: opacity 0.3s ease
input:-moz-placeholder, textarea:-moz-placeholder
   opacity: 1
   transition: opacity 0.3s ease
input:-ms-input-placeholder, textarea:-ms-input-placeholder
   opacity: 1
   transition: opacity 0.3s ease
input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder
   opacity: 0
   transition: opacity 0.3s ease
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder
   opacity: 0
   transition: opacity 0.3s ease
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder
   opacity: 0
   transition: opacity 0.3s ease
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder
   opacity: 0
   transition: opacity 0.3s ease

.scroll
   overflow-x: scroll
   scrollbar-width: none
   -ms-overflow-style: none

   &::-webkit-scrollbar
      display: none

a:hover
   color: #EDB133 !important

a:active
   color: #FFCC66 !important

.swiper-wrapper
  display: flex

.broker-select__control
   background-color:#EFEFEF !important
   min-height: 46px !important
   border-color:#CECECE !important
   color: #383838 !important
.broker-select__multi-value
   background-color: #ffffff !important


.react-ios-time-picker-popup
   position: fixed
   top: 50%  !important
   left: 50%  !important
   transform: translate(-50%, -50%)
   bottom: 8% !important
.react-ios-time-picker-btn-cancel
   color: #fff !important
.react-ios-time-picker-btn
   font-weight: 500 !important
.react-ios-time-picker-main
   margin-top: 0px
.broker-select__placeholder
   color: #FF4343 !important
.errorSelect
   color: #FF4343 !important



// DatePicker
.react-datepicker-popper
   z-index: 10 !important
.react-datepicker
   border: 0.5px solid #E9E9E9,
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06)
   border-radius: 8px !important
   background-color: #FFFFFF !important
.react-datepicker__day--today
   border: 1px solid #9B8EFF !important
   color: #242424 !important
.react-datepicker__day
   color: #242424 !important
   width: 30px !important
   height: 30px !important
   font-weight: 500 !important
   font-size: 13px !important
   line-height: 30px !important
   border-radius: 8px !important
.react-datepicker__day:hover
   background: #E0DDFD !important
   color: #6553F5 !important
.react-datepicker__day-name
   color: #898989 !important
   width: 30px !important
   height: 30px !important
   font-weight: 500 !important
   font-size: 13px !important
   line-height: 30px !important
.react-datepicker__header
   background: #ffffff !important
   border: none !important
   padding: 4px 0 !important
   border-radius: 8px !important
.react-datepicker__day--in-range
   background: #E0DDFD !important
   color: #6553F5 !important
.react-datepicker__day--in-selecting-range
   background: #E0DDFD !important
   color: #6553F5 !important
.react-datepicker__day--selected
   background: #6553F5 !important
   color: #ffffff !important
.react-datepicker__day--keyboard-selected
   background: transparent !important
   color: #242424 !important
.react-datepicker__day--keyboard-selected:hover
   background: #E0DDFD !important
   color: #6553F5 !important
.react-datepicker__day--disabled
   color: #898989 !important
   background: transparent !important
.react-datepicker__day--disabled:hover
   color: #898989 !important
   background: transparent !important
.react-datepicker-popper[data-placement^=bottom]
   padding-top: 4px !important
.react-datepicker-popper[data-placement^=top]
   padding-bottom: 4px !important
.react-datepicker__day--outside-month
   visibility: hidden !important
.react-datepicker-time__caption
   display: none !important
.react-datepicker__input-time-container
   border-top: 1px solid #E0E0E0 !important
   margin: 0 !important
   float: none !important
   padding: 10px 6px 8px 6px !important
.react-datepicker-time__input
   margin-left: 0px !important

.react-datepicker__month
   margin: 0 !important
// DatePicker