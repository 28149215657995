.toast
  align-items: flex-start
  border-radius: 8px
  color: #ffffff
  display: flex
  padding: 12px
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
  transition: transform .5s ease-in-out
  animation: toast-in-right .5s
  cursor: pointer
  &:not(:last-child)
    margin: 0 0 12px
  

.toast__content
  flex: 1 1 auto
  margin: 0 0 0 12px
  overflow: hidden
  text-overflow: ellipsis
  .title
    font-weight: 400
    font-size: 15px
    line-height: 18px
  .text
    font-weight: 400
    font-size: 13px
    line-height: 16px
    color: #898989


.toast__dismiss
  cursor: pointer

.toasts
  top: 50px
  position: fixed
  right: 24px
  width: 300px
  z-index: 999


@keyframes toast-in-right
  0%
    transform: translateX(100%)
  100%
    transform: translateX(0)