.button {
  cursor: pointer;
  outline: none;
  &.disabled {
    background-color: #E0E0E0;
    color: #ABABAB;
    cursor: not-allowed;
  }

  &.stretch {
    width: 100%;
  }

  // size
  &.m {
    padding: 18px 36px;
  }

  &.s {
    padding-left: 50px;
    padding-right: 50px;
    height: 44px;
  }

  &.xs {
    padding: 4px 8px;
  }

  // variant
  &.yellow {
    background: linear-gradient(94.21deg, #FEC863 1.99%, #F6A442 100%);
    border-width: 0;

    &:hover {
    //   background: linear-gradient(274.12deg, #FEC863 0%, #F6A442 100%);
  
      // TODO: Это конфликт с глобальными стилями
      // Удалить когда конфликт решится
      box-shadow: none;
    }
  }

  &.orange {
    background: linear-gradient(271.3deg, #FFAB7B 0.37%, #FCC380 100%, #FCC380 100%);
    border: 0;

    &:hover {
      background: #FFAB7B;
    }
  }

  &.outline {
    border: 2px solid #BABABA;
    color: #898989;

    // TODO: Это конфликт с глобальными стилями
    // Удалить когда конфликт решится
    background: none;

    &:hover {
      color: #383838;
      border-color: transparent;
      background: linear-gradient(274.12deg, #FEC863 0%, #F6A442 100%);

      // TODO: Это конфликт с глобальными стилями
      // Удалить когда конфликт решится
      box-shadow: none;
    }
  }

  &.line {
    color: #fff;
    background: none;
    border-width: 0;
    padding: 0;
    display: flex;
    align-items: center;

    &:hover {
      // TODO: Это конфликт с глобальными стилями
      // Удалить когда конфликт решится
      box-shadow: none;
    }
  }

  .leftIcon {
    margin-right: 14px;
    
    img {
      display: block;
    }
  }

  .iconWithoutMargins {
    margin-left: 0;
    margin-right: 0;
  }
}