.errorWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error {
  font-size: 24px;
  margin-bottom: 16px;
}

